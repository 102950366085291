export const CALL = 1
export const SMS = 2
export const CONFERENCE = 3
export const RVM = 4
// reserved types
export const EMAIL = 5
// social
export const FACEBOOK = 6
export const WHATSAPP = 7
export const FAX = 8
// user and system notes
export const NOTE = 10
export const SYSNOTE = 11
// appointment
export const APPOINTMENT = 12
// reminder
export const REMINDER = 13

export const NOTE_TYPES = [
  NOTE,
  SYSNOTE
]

// string types
export const CALL_TYPE = 'call'
export const SMS_TYPE = 'sms'
export const MMS_TYPE = 'MMS'
export const EMAIL_TYPE = 'email'
export const FAX_TYPE = 'fax'
export const ANY_COMMUNICATION_TYPE = 'all'
