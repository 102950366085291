// Types
export const ALL = 'all'
export const TYPE_TEXT = 1
export const TYPE_VOICE = 2

// Directions
export const DIRECTION_INBOUND = 1
export const DIRECTION_OUTBOUND = 2

// Enrollment Types
export const ENROLLMENT_TYPE_TEXT = 1
export const ENROLLMENT_TYPE_VOICE = 2
