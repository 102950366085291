export const INBOUND = 1
export const OUTBOUND = 2
export const ALL_DIRECTIONS = [
  INBOUND,
  OUTBOUND
]

// string directions
export const INBOUND_STRING = 'Inbound'
export const OUTBOUND_STRING = 'Outbound'
