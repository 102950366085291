// user follows the default outbound calling logic
export const OUTBOUND_CALLING_MODE_ACCOUNT_DEFAULT = 1
// user will always be asked to select an outbound campaign
export const OUTBOUND_CALLING_MODE_ACCOUNT_ALWAYS_ASK = 2

// Options values for the outbound calling mode selector
export const OUTBOUND_CALLING_MODE_SELECTOR_USER_SELECT_MANUALLY = 1
export const OUTBOUND_CALLING_MODE_SELECTOR_USER_USE_COMPANY_DEFAULT = 2
export const OUTBOUND_CALLING_MODE_SELECTOR_USER_ALWAYS_ASK = 3

// Real values for the outbound calling mode user level
export const OUTBOUND_CALLING_MODE_USER_DEFAULT = 1
export const OUTBOUND_CALLING_MODE_USER_ALWAYS_ASK = 2
